import React from 'react';
import Auth from '@/components/Auth';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import Card from '@/components/ui/Card';
import Icon from '@/components/ui/Icon';
import SectionTitle from '@/components/ui/SectionTitle';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const FeatureItem = ({
  icon,
  title,
  desc,
  auto,
  variant
}) => <li className="feature-item" data-sentry-component="FeatureItem" data-sentry-source-file="Features.jsx">
    {icon && <Icon name={icon} className={cn('text-2xl mt-[0.1rem] shrink-0', {
    [`text-${variant}`]: variant
  })} />}

    <div className="flex flex-col gap-1">
      <h4 className="inline-flex items-center mb-0 text-xl text-balance">
        {title}

        {auto && <Badge variant={`${variant}-light`} className="ml-3 font-bold uppercase" icon="Sparkles" label="Auto" />}
      </h4>

      <p className="text-sm md:text-base opacity-90 text-balance">
        {desc}
      </p>
    </div>
  </li>;
const Features = ({
  showTitle = false,
  className
}) => {
  const t = useTranslations();
  const features = [{
    image: '/images/home/matchfy_features_artists.jpg',
    title: 'home.features.artist.title',
    color: 'primary',
    ctaLabel: 'home.features.artist.cta',
    ctaLink: ROUTES.MATCH,
    list: [{
      icon: 'Music',
      title: 'home.features.artist.list.1.title',
      body: 'home.features.artist.list.1.body'
    }, {
      icon: 'UserRoundPlus',
      title: 'home.features.artist.list.2.title',
      body: 'home.features.artist.list.2.body',
      auto: true
    }, {
      icon: 'FastForward',
      title: 'home.features.artist.list.3.title',
      body: 'home.features.artist.list.3.body',
      auto: true
    }]
  }, {
    image: '/images/home/matchfy_features_curators.jpg',
    title: 'home.features.curator.title',
    color: 'secondary',
    ctaLabel: 'home.features.curator.cta',
    ctaLink: ROUTES.PLAYLISTS,
    list: [{
      icon: 'MessageCircle',
      title: 'home.features.curator.list.1.title',
      body: 'home.features.curator.list.1.body',
      auto: true
    }, {
      icon: 'ListMusic',
      title: 'home.features.curator.list.2.title',
      body: 'home.features.curator.list.2.body',
      auto: true
    }, {
      icon: 'Coins',
      title: 'home.features.curator.list.3.title',
      body: 'home.features.curator.list.3.body'
    }]
  }];
  const classes = cn({
    features: true,
    section: true,
    container: true,
    [className]: className
  });
  return <section id="features" className={classes} data-sentry-component="Features" data-sentry-source-file="Features.jsx">
      {showTitle && <SectionTitle tag="h3" center title={t('features.title')} subtitle={t('features.subtitle')} badge={<Badge variant="primary-light" icon={ROUTES_DATA.AFFILIATE.icon} label={t('affiliate.how_does_it_work.title')} />} />}

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:gap-12">
        {features.map((feature, index) => <Card key={index} className={`card-features card-${feature.color}`} image={feature.image} overlayTitle={t(feature.title)} overlay={true} overlayColor={feature.color} footer={<Auth login loginClasses="button-wide" loginVariant={feature.color}>
                <Button wide href={feature.ctaLink} variant={feature.color}>
                  {t(feature.ctaLabel)}
                </Button>
              </Auth>}>
            {feature.list.map((item, index) => <FeatureItem key={index} auhref={item.auto} icon={item.icon} variant={feature.color} title={t(item.title)} desc={t(item.body, {
          shouldParse: true
        })} />)}
          </Card>)}
      </div>
    </section>;
};
export default Features;