'use client';

import { useContext, useEffect, useRef } from 'react';
import { ThemeContext } from '@/context/ThemeProvider';
import { AVAILABLE_LANGUAGES } from '@/data';
import { TRUSTPILOT_LINK } from '@/data/trustpilot';
import { useLocale, useTranslations } from '@/hooks';
import { cn } from '@/utils';
const TrustpilotMicroComboWidget = ({
  className
}) => {
  const t = useTranslations();
  const {
    currentTheme
  } = useContext(ThemeContext);
  const {
    locale
  } = useLocale();
  const widgetRef = useRef(null);
  const language = AVAILABLE_LANGUAGES.find(lang => lang.value === locale);
  const classes = cn('trustpilot-widget-wrapper', className);
  useEffect(() => {
    if (widgetRef.current) {
      widgetRef.current.setAttribute('data-theme', currentTheme ?? 'light');
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(widgetRef.current, true);
      }
    }
  }, [currentTheme]);
  return <div className={classes} data-sentry-component="TrustpilotMicroComboWidget" data-sentry-source-file="TrustpilotMicroComboWidget.jsx">
      <div ref={widgetRef} className="trustpilot-widget" data-locale={language?.bpc47 ?? 'en-US'} data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="62e005c88f96c51a8665f08f" data-style-height="20px" data-style-width="100%" data-theme={currentTheme ?? 'light'}>
        <a href={TRUSTPILOT_LINK} target="_blank" rel="noopener noreferrer" className="hidden">
          Trustpilot
        </a>
      </div>
    </div>;
};
export default TrustpilotMicroComboWidget;