'use client';

import { nanoid } from 'nanoid';
import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import sanityClient from '@/api/sanity/client';
import { reviewQuery } from '@/api/sanity/queries';
import Badge from '@/components/ui/Badge';
import ConditionalLink from '@/components/ui/ConditionalLink';
import Date from '@/components/ui/Date';
import StarRating from '@/components/ui/Rating';
import SectionTitle from '@/components/ui/SectionTitle';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export function Reviews({
  className
}) {
  const [reviews, setReviews] = useState([]);
  const classes = cn({
    reviews: true,
    section: true,
    'container mx-auto': true,
    [className]: className
  });
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await sanityClient.fetch(reviewQuery);
        setReviews(reviewsData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReviews();
  }, []);
  const renderReviews = reviews.map(item => {
    return <ConditionalLink key={nanoid()} className="review-card" href={`${item.url}?ref=matchfy`} condition={item.url && item.url.length > 0} external>
        <div className="review-card-header">
          <StarRating rating={item.rating} />
          <Date dateString={item.date} />
        </div>
        <div className="review-card-body">
          <span>{item.reviewer}</span>
          <h5>{item.title}</h5>
          {item.text && <p>{item.text}</p>}
        </div>
      </ConditionalLink>;
  });
  const t = useTranslations();
  return <div className={classes} data-sentry-component="Reviews" data-sentry-source-file="Reviews.jsx">
      <SectionTitle tag="h3" title={t('reviews.page.title')} subtitle={t('reviews.page.subtitle')} center badge={<Badge variant="gold-light" label={t('reviews.title')} icon="Star" />} data-sentry-element="SectionTitle" data-sentry-source-file="Reviews.jsx" />

      <Marquee className="review-marquee" gradientWidth={50} pauseOnHover speed={12} data-sentry-element="Marquee" data-sentry-source-file="Reviews.jsx">
        {reviews && reviews.length > 0 ? renderReviews : null}
      </Marquee>
    </div>;
}
export default Reviews;