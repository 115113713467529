'use client';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Badge from '@/components/ui/Badge';
import Card from '@/components/ui/Card';
import SectionTitle from '@/components/ui/SectionTitle';
import { MATCHFY_BLOG_API_URL, GHOST_KEY, ROUTES, ROUTES_DATA } from '@/data';
import { useTranslations } from '@/hooks';
import { cn, readMore } from '@/utils';
const Blog = ({
  className
}) => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postData = await axios.get(`${MATCHFY_BLOG_API_URL}/posts/?key=${GHOST_KEY}&limit=4`);
        setPosts(postData.data.posts);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPosts();
  }, []);
  const t = useTranslations();
  const classes = cn({
    blog: true,
    section: true,
    'container mx-auto': true,
    [className]: className
  });
  if (!posts || posts.length === 0) {
    return null;
  }
  return <section id="blog" className={classes} data-sentry-component="Blog" data-sentry-source-file="Blog.jsx">
      <SectionTitle tag="h3" center title={t('blog.page.title')} subtitle={t('blog.page.subtitle')} badge={<Badge variant="primary-light" icon={ROUTES_DATA.BLOG.icon} label="News" />} data-sentry-element="SectionTitle" data-sentry-source-file="Blog.jsx" />

      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
        {posts && posts.length > 0 && posts.map(post => <Card key={post.id} className="card-blog" href={post.url} external={true} image={post.feature_image} title={post.title} titleTag="h4" text={readMore(post.excerpt)} />)}
      </div>

      <div className="w-full mt-8 text-center">
        <a href={ROUTES.BLOG} target="_blank" rel="noopener noreferrer" className="button button-cta button-outline">
          {t('home.blog.read_more')}
        </a>
      </div>
    </section>;
};
export default Blog;