'use client';

import React, { useEffect, useState } from 'react';
import sanityClient from '@/api/sanity/client';
import { mentionQuery } from '@/api/sanity/queries';
import Badge from '@/components/ui/Badge';
import ConditionalLink from '@/components/ui/ConditionalLink';
import SectionTitle from '@/components/ui/SectionTitle';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const Mentions = ({
  className
}) => {
  const [mentions, setMentions] = useState([]);
  const t = useTranslations();
  const classes = cn({
    reviews: true,
    section: true,
    'container mx-auto': true,
    [className]: className
  });
  useEffect(() => {
    const fetchMentions = async () => {
      try {
        const data = await sanityClient.fetch(mentionQuery);
        setMentions(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMentions();
  }, []);
  const renderMentions = mentions.map((item, i) => {
    if (!item?.image) return null;
    const {
      invertLogo
    } = item;
    const classes = cn({
      'mentions-grid-item': true,
      'invert-logo': invertLogo
    });
    return <div key={i} className={classes}>
        <ConditionalLink href={`${item.url}?ref=matchfy`} condition={item.url && item.url.length > 0} external={true}>
          <div className="mentions-grid-item-image">
            <img src={item.image.url} alt={item.title} />
          </div>
          <h5>{item?.title}</h5>
        </ConditionalLink>
      </div>;
  });
  const gridClasses = cn({
    grid: true,
    'grid-cols-2': true,
    'md:grid-cols-3': true,
    'lg:grid-cols-6': true,
    'gap-x-4 gap-y-8 md:gap-4': true
  });
  return <div id="mentions" className={classes} data-sentry-component="Mentions" data-sentry-source-file="Mentions.jsx">
      <SectionTitle tag="h3" center title={t('home.mentioned_by.title')} subtitle={t('home.mentioned_by.subtitle')} badge={<Badge variant="blue-light" label={t('common.labels.mentions')} icon="PencilLine" />} data-sentry-element="SectionTitle" data-sentry-source-file="Mentions.jsx" />
      <div className={gridClasses}>
        {mentions && mentions.length > 0 ? renderMentions : null}
      </div>
    </div>;
};
export default Mentions;