'use client';

import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { matchfyApi } from '@/api';
import { CuratorCard } from '@/components/Curator';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import SectionTitle from '@/components/ui/SectionTitle';
import Spinner from '@/components/ui/Spinner';
import { ROUTES, ROUTES_DATA } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const Curators = ({
  className
}) => {
  const t = useTranslations();
  const {
    data: starCurators,
    isLoading: isLoadingStar
  } = useQuery({
    queryKey: ['starCurators'],
    queryFn: async () => {
      const data = await matchfyApi.getStarCurators();
      return data.slice(0, 4);
    }
  });
  const {
    data: featuredCurators,
    isLoading: isLoadingFeatured
  } = useQuery({
    queryKey: ['featuredCurators'],
    queryFn: async () => {
      const data = await matchfyApi.getFeaturedCurators();
      return data.slice(0, 4);
    }
  });
  const renderCurators = ({
    curators,
    ...props
  }) => {
    return curators.map(curator => {
      if (!curator) return null;
      return <CuratorCard key={curator?.id} curator={curator} {...props} />;
    });
  };
  const classes = cn({
    curators: true,
    section: true,
    'container mx-auto': true,
    [className]: className
  });
  return <section id="curators" className={classes} data-sentry-component="Curators" data-sentry-source-file="Curators.jsx">
      {starCurators?.length > 0 && <SectionTitle tag="h3" center title={t('curator.star.title')} subtitle={t('curator.star.subtitle')} badge={<Badge variant="gold-light" icon={ROUTES_DATA.STARS.icon} label="Stars" />} />}

      {isLoadingStar && <Spinner />}

      {starCurators?.length > 0 && <div className="grid grid-cols-2 gap-4 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 md:gap-8">
          {renderCurators({
        curators: starCurators,
        star: true
      })}
        </div>}

      {featuredCurators?.length > 0 && <SectionTitle tag="h3" center title={t('curator.featured.title')} subtitle={t('curator.featured.subtitle')} badge={<Badge variant="primary-light" icon={ROUTES_DATA.CURATORS.icon} label={t('navigation.curators')} />} />}

      {isLoadingFeatured && <Spinner />}

      {featuredCurators?.length > 0 && <div className="grid grid-cols-2 gap-4 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 md:gap-8">
          {renderCurators({
        curators: featuredCurators,
        featured: true
      })}
        </div>}

      <div className="w-full mt-8 text-center">
        <Button className="button-cta" href={ROUTES.CURATORS} variant="outline" data-sentry-element="Button" data-sentry-source-file="Curators.jsx">
          {t('common.labels.learn_more')}
        </Button>
      </div>
    </section>;
};
export default Curators;