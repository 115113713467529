'use client';

import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { PlaylistCell } from '@/components/Playlist';
import { LogoIconColor } from '@/components/ui/Logo/Logo';
import { PLAYLIST_COVER_DATA } from '@/data';
import { shuffle } from '@/utils';
const CARD_OFFSET = 20;
const STACK_SIZE = 3;
const SCALE_FACTOR = 0.2;
const ROTATION_INTERVAL = 2500;
export const PlaylistStack = ({
  className
}) => {
  const [playlists, setPlaylists] = React.useState(() => {
    const logoSlide = {
      id: 'logo',
      isLogo: true
    };
    const randomPlaylists = shuffle(PLAYLIST_COVER_DATA).map((playlist, idx) => ({
      id: `playlist-${idx}`,
      title: playlist.title,
      image: `/images/covers/cover_${++idx}.jpg`,
      isLogo: false
    })).slice(0, STACK_SIZE);
    return [logoSlide, ...randomPlaylists];
  });
  const rotateAndShuffle = () => {
    setPlaylists(prevPlaylists => {
      const [_removedItem, ...remainingPlaylists] = prevPlaylists;
      const availableItems = PLAYLIST_COVER_DATA.filter(item => !remainingPlaylists.some(p => p.title === item.title));
      const randomItem = shuffle(availableItems)[0];
      const newItem = {
        id: `playlist-${Date.now()}`,
        title: randomItem.title,
        image: `/images/covers/cover_${PLAYLIST_COVER_DATA.indexOf(randomItem) + 1}.jpg`,
        isLogo: false
      };
      return [...remainingPlaylists, newItem];
    });
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      rotateAndShuffle();
    }, ROTATION_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);
  return <div className={className} data-sentry-component="PlaylistStack" data-sentry-source-file="PlaylistStack.jsx">
      <ul className="relative w-32 h-32">
        {playlists.map((playlist, index) => <motion.li key={playlist.id} className="absolute transform-origin-top-center" animate={{
        top: index * -CARD_OFFSET,
        scale: 1 - index * SCALE_FACTOR,
        zIndex: playlists.length - index,
        opacity: 1 - index * 0.2
      }} transition={{
        duration: 0.4,
        ease: "easeInOut"
      }}>
            {playlist.isLogo ? <div className="flex items-center justify-center w-32 h-32">
                <LogoIconColor />
              </div> : <PlaylistCell hideTitle={true} overlay={false} title={playlist.title} image={playlist.image} className="pointer-events-none playlist-cell" />}
          </motion.li>)}
      </ul>
    </div>;
};
export default PlaylistStack;