'use client';

import React, { useState, useEffect } from 'react';
import sanityClient from '@/api/sanity/client';
import { releaseQuery } from '@/api/sanity/queries';
import Badge from '@/components/ui/Badge';
import Card from '@/components/ui/Card';
import SectionTitle from '@/components/ui/SectionTitle';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const PromoReleases = ({
  className
}) => {
  const [releases, setReleases] = useState([]);
  useEffect(() => {
    const fetchReleases = async () => {
      try {
        const releasesData = await sanityClient.fetch(releaseQuery);
        const releasesFilter = releasesData.filter(item => item.show === true);
        setReleases(releasesFilter);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReleases();
  }, []);
  const t = useTranslations();
  const classes = cn({
    releases: true,
    section: true,
    'container mx-auto': true,
    [className]: className
  });
  if (!releases || releases.length === 0) {
    return null;
  }
  return <div id="releases" className={classes} data-sentry-component="PromoReleases" data-sentry-source-file="PromoReleases.jsx">
      <SectionTitle tag="h3" center title={t('promo.releases.title')} subtitle={t('promo.releases.subtitle')} badge={<Badge variant="primary-light" icon="Disc3" label="Promo" />} data-sentry-element="SectionTitle" data-sentry-source-file="PromoReleases.jsx" />

      <div className="grid grid-cols-2 gap-4 md:gap-8 md:grid-cols-4 lg:grid-cols-6">
        {releases && releases.length > 0 && releases.map(release => <Card key={release._id} className="card-release" href={release.url} external={true} image={release?.coverImage?.url} title={release.title} titleTag="h4">
              <p>{release.artist}</p>
              <span>{release.label}</span>
            </Card>)}
      </div>
    </div>;
};
export default PromoReleases;