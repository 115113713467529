import { Link } from '@/i18n/routing';
export const ConditionalLink = ({
  children,
  href,
  condition,
  external,
  ...props
}) => {
  if (!!condition && !external && href) {
    return <Link href={href} {...props}>
        {children}
      </Link>;
  }
  if (!!condition && external && href) {
    return <a href={href} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>;
  }
  return <div {...props} href={null} data-sentry-component="ConditionalLink" data-sentry-source-file="ConditionalLink.jsx">
      {children}
    </div>;
};
export default ConditionalLink;