'use client';

import Link from 'next/link';
import React from 'react';
import { COLLABS } from '@/data';
import { useTranslations } from '@/hooks';
export const Collabs = ({
  show = true,
  className
}) => {
  const t = useTranslations();
  const activeCollabs = COLLABS.filter(collab => collab?.show);
  if (!show || activeCollabs.length === 0) return null;
  return <div className={className} data-sentry-component="Collabs" data-sentry-source-file="Collabs.jsx">
      <h4 className="mb-1 text-lg font-medium opacity-70">
        {t('collabs.title')}
      </h4>

      <div className="flex flex-wrap items-center justify-center gap-8">
        {activeCollabs.map(collab => <Link href={collab.cta} target="_blank" key={collab.id} className="flex items-center justify-center w-32 h-12 gap-2 opacity-40 hover:opacity-80">
            {React.cloneElement(collab.logo, {
          className: 'w-full h-full'
        })}
          </Link>)}
      </div>
    </div>;
};
export default Collabs;