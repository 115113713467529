import React from 'react';
import Alert from '@/components/ui/Alert';
import Badge from '@/components/ui/Badge';
import Card from '@/components/ui/Card';
import Icon from '@/components/ui/Icon';
import SectionTitle from '@/components/ui/SectionTitle';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const PromoCompare = ({
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    section: true,
    container: true,
    [className]: className
  });
  const compareList = {
    traditional: [{
      title: 'promo.compare.services.traditional.features.safe.title',
      text: 'promo.compare.services.traditional.features.safe.text',
      icon: 'VolumeOff'
    }, {
      title: 'promo.compare.services.traditional.features.playlist.title',
      text: 'promo.compare.services.traditional.features.playlist.text',
      icon: 'HeadphoneOff'
    }],
    organic: [{
      title: 'promo.compare.services.organic.features.safe.title',
      text: 'promo.compare.services.organic.features.safe.text',
      icon: 'CircleCheck'
    }, {
      title: 'promo.compare.services.organic.features.playlist.title',
      text: 'promo.compare.services.organic.features.playlist.text',
      icon: 'Volume2'
    }]
  };
  return <div id="promo-compare" className={classes} data-sentry-component="PromoCompare" data-sentry-source-file="PromoCompare.jsx">
      <SectionTitle center tag="h3" className="pt-8" headingClasses="text-gradient-light-blue-fade text-balance max-w-[32ch] mx-auto" title={t('promo.compare.header.title')} subtitle={t('promo.compare.header.subtitle')} badge={<Badge variant="blue-light" label={t('promo.compare.header.badge')} icon="Volume2" />} data-sentry-element="SectionTitle" data-sentry-source-file="PromoCompare.jsx" />

      <Card className="p-4 md:p-8" data-sentry-element="Card" data-sentry-source-file="PromoCompare.jsx">
        <div className="grid gap-16 md:grid-cols-2">
          <div>
            <h5>
              {t('promo.compare.services.traditional.title')}
            </h5>

            <p className="mb-6 text-lg">
              {t('promo.compare.services.traditional.text')}
            </p>

            <img src="/images/home/matchfy_fake_promo.jpg" alt="Matchfy Fake Promo" className="mb-4 border rounded-xl" />

            {compareList?.traditional.length > 0 && <div className="flex flex-col gap-3">
                {compareList.traditional.map((item, index) => <div key={index} className="flex items-start gap-3 p-3 rounded-lg border-outline bg-secondary">
                    <Icon className="shrink-0" name={item.icon} />

                    <div className="flex flex-col gap-1">
                      <h6 className="mb-0 text-balance">
                        {t(item.title)}
                      </h6>

                      <p className="text-balance">
                        {t(item.text, {
                    shouldParse: true
                  })}
                      </p>
                    </div>
                  </div>)}
              </div>}
          </div>

          <div>
            <h5 className="text-blue">
              {t('promo.compare.services.organic.title')}
            </h5>

            <p className="mb-6 text-lg">
              {t('promo.compare.services.organic.text')}
            </p>

            <img src="/images/home/matchfy_legit_promo.jpg" alt="Matchfy Legit Promo" className="mb-4 border rounded-xl" />

            {compareList?.organic.length > 0 && <div className="flex flex-col gap-3">
                {compareList.organic.map((item, index) => <div key={index} className="flex items-start gap-3 p-3 border-outline bg-primary rounded-xl">
                    <Icon className="text-blue shrink-0" name={item.icon} />

                    <div className="flex flex-col gap-1">
                      <h6 className="mb-0 text-balance">
                        {t(item.title)}
                      </h6>

                      <p className="text-balance">
                        {t(item.text, {
                    shouldParse: true
                  })}
                      </p>
                    </div>
                  </div>)}
              </div>}
          </div>
        </div>

        <Alert variant="info" className="mt-4" contentClasses="text-center" data-sentry-element="Alert" data-sentry-source-file="PromoCompare.jsx">
          <span>{t('promo.compare.tip', {
            shouldParse: true
          })}</span>
        </Alert>
      </Card>
    </div>;
};
export default PromoCompare;