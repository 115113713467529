'use client';

import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import SectionTitle from '@/components/ui/SectionTitle';
import Video from '@/components/ui/Video';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const HowItWorks = ({
  showTitle,
  className
}) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const videoRefs = useRef([]);
  const t = useTranslations();
  const classes = cn({
    section: true,
    container: true,
    [className]: className
  });
  const slides = [{
    title: t('home.how_it_works.slide1.title')
  }, {
    title: t('home.how_it_works.slide2.title')
  }, {
    title: t('home.how_it_works.slide3.title')
  }, {
    title: t('home.how_it_works.slide4.title')
  }];
  const startVideo = index => {
    const video = videoRefs.current[index];
    if (video) {
      video.seekTo(0);
    }
  };
  const handleVideoChange = () => {
    if (currentSlide < slides.length - 1) {
      sliderRef.slideNext();
    } else {
      sliderRef.slideTo(0);
    }
  };
  return <div id="how-it-works" className={classes} data-sentry-component="HowItWorks" data-sentry-source-file="HowItWorks.jsx">
      {showTitle && <SectionTitle tag="h3" center title={t('home.how_it_works.title')} subtitle={t('home.how_it_works.subtitle')} badge={<Badge variant="primary-light" icon="ListMusic" label={t('affiliate.how_does_it_work.title')} />} />}

      <Swiper className="slider" onSwiper={setSliderRef} spaceBetween={20} onSlideChange={swiper => {
      const activeIndex = swiper.activeIndex;
      setCurrentSlide(activeIndex);
      startVideo(activeIndex);
    }} data-sentry-element="Swiper" data-sentry-source-file="HowItWorks.jsx">
        {slides.map((_slide, index) => <SwiperSlide key={index}>
            <Video ref={el => videoRefs.current[index] = el} autoPlay={true} playing={true} muted={true} controls={false} playsinline={true} onEnded={handleVideoChange} src={`/video/matchfy_how_to_step_${index + 1}.mp4`} />
          </SwiperSlide>)}
      </Swiper>

      <div className="slider-navigation">
        {slides.map((slide, index) => <Button key={index} variant="clear" className={cn('slider-navigation-item', {
        active: currentSlide === index
      })} onClick={() => {
        sliderRef.slideTo(index);
        startVideo(index);
      }}>
            <span className="index">{index + 1}</span>
            <h6 className="m-0">{slide.title}</h6>
          </Button>)}
      </div>
    </div>;
};
export default HowItWorks;