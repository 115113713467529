'use client';

import { motion } from 'framer-motion';
import React from 'react';
import Auth from '@/components/Auth';
import { Collabs, PromoCard, PlaylistStack } from '@/components/Home';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { TrustpilotMicroComboWidget } from '@/components/ui/Trustpilot';
import { ROUTES, COUNTERS } from '@/data';
import { useTranslations } from '@/hooks';
import { formatNumber } from '@/utils';
const HomeHero = () => {
  const t = useTranslations();
  const animation = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: {
      opacity: 1,
      y: 0
    }
  };
  return <section className="container" data-sentry-component="HomeHero" data-sentry-source-file="HomeHero.jsx">
      <div className="home-hero">
        <div className="home-hero-content">
          <PlaylistStack className="mt-8 mb-6" data-sentry-element="PlaylistStack" data-sentry-source-file="HomeHero.jsx" />

          <motion.h1 {...animation} data-sentry-element="unknown" data-sentry-source-file="HomeHero.jsx">
            {t('home.main.title')}
          </motion.h1>

          <motion.h2 data-sentry-element="unknown" data-sentry-source-file="HomeHero.jsx">
            {t('home.main.subtitle')}
          </motion.h2>

          <Auth login loginClasses="button-cta mt-2" data-sentry-element="Auth" data-sentry-source-file="HomeHero.jsx">
            <Button href={ROUTES.MATCH} variant="gradient" className="mt-2 button-cta" icon="ArrowRight" data-sentry-element="Button" data-sentry-source-file="HomeHero.jsx">
              {t('home.main.start_now')}
            </Button>
          </Auth>

          <div className="flex flex-col flex-wrap items-center justify-center gap-2 mt-8 sm:flex-row">
            <Badge variant="primary-light" icon="ArrowLeftRight" className="px-4 text-base font-semibold md:text-lg" value={t('home.solution.submissions.counter')} label={formatNumber(COUNTERS.SUBMISSIONS)} data-sentry-element="Badge" data-sentry-source-file="HomeHero.jsx" />

            <Badge variant="primary-light" icon="UserCheck" className="px-4 text-base font-semibold md:text-lg" value={t('home.solution.artists.counter')} label={formatNumber(COUNTERS.ARTISTS)} data-sentry-element="Badge" data-sentry-source-file="HomeHero.jsx" />

            <Badge variant="primary-light" icon="ListMusic" className="px-4 text-base font-semibold md:text-lg" value={t('home.solution.playlists.counter')} label={formatNumber(COUNTERS.PLAYLISTS)} data-sentry-element="Badge" data-sentry-source-file="HomeHero.jsx" />
          </div>

          <TrustpilotMicroComboWidget className="w-full mt-6" data-sentry-element="TrustpilotMicroComboWidget" data-sentry-source-file="HomeHero.jsx" />

          <Collabs show={true} className="mt-8" data-sentry-element="Collabs" data-sentry-source-file="HomeHero.jsx" />

          <a className="mt-8 home-hero-scroll-down" href="#intro">
            <Icon name="ArrowDown" className="mr-2" data-sentry-element="Icon" data-sentry-source-file="HomeHero.jsx" />
            {t('home.main.scroll_down')}
          </a>
        </div>

        <PromoCard className="my-8" data-sentry-element="PromoCard" data-sentry-source-file="HomeHero.jsx" />
      </div>
    </section>;
};
export default HomeHero;