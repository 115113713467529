'use client';

import React, { useState, useEffect } from 'react';
import sanityClient from '@/api/sanity/client';
import { partnerQuery } from '@/api/sanity/queries';
import Badge from '@/components/ui/Badge';
import SectionTitle from '@/components/ui/SectionTitle';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export function Partners({
  className
}) {
  const [partners, setPartners] = useState([]);
  const classes = cn({
    partners: true,
    section: true,
    'container mx-auto': true,
    [className]: className
  });
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const partnerData = await sanityClient.fetch(partnerQuery);
        const partnerFilter = partnerData.filter(item => item.featured === false);
        setPartners(partnerFilter);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPartners();
  }, []);
  const renderPartners = partners.map(item => {
    return <div key={item.title} className="partners-grid-item">
        <img src={item.image.url} alt={item.title} />
      </div>;
  });
  const t = useTranslations();
  return <div className={classes} data-sentry-component="Partners" data-sentry-source-file="Partners.jsx">
      <SectionTitle tag="h3" title={t('partners.title')} subtitle={t('partners.subtitle')} center badge={<Badge variant="primary-light" icon="HeartHandshake" label="Partners" />} data-sentry-element="SectionTitle" data-sentry-source-file="Partners.jsx" />
      <div className="partners-grid">
        {partners && partners.length > 0 ? renderPartners : null}
      </div>
    </div>;
}
export default Partners;