'use client';

import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';
import { cn, formatNumber } from '@/utils';
export const Counter = ({
  start,
  end,
  duration = 2,
  formatFn = formatNumber,
  className,
  interval
}) => {
  const [currentEnd, setCurrentEnd] = useState(end);
  const count = useMotionValue(start);
  const rounded = useTransform(count, latest => formatFn(Math.round(latest)));
  useEffect(() => {
    const controls = animate(count, currentEnd, {
      duration,
      ease: 'easeInOut'
    });
    return controls.stop;
  }, [currentEnd, count, duration]);
  useEffect(() => {
    if (interval) {
      const intervalId = setInterval(() => {
        setCurrentEnd(prevEnd => prevEnd + Math.floor(Math.random() * 1000));
      }, interval);
      return () => clearInterval(intervalId);
    }
  }, [interval]);
  const classes = cn({
    counter: true,
    [className]: className
  });
  return <motion.span className={classes} data-sentry-element="unknown" data-sentry-component="Counter" data-sentry-source-file="Counter.jsx">{rounded}</motion.span>;
};
export default Counter;